import '../assets/styles/components/Card.sass'

export default function Card({ project }) {


  return (
    <div className="xl:w-[225px] 2xl:w-[300px]">
      <a href={project.slug ? `/projetos/${project.slug}` : `${project.url}`} >
        {project.sellpercent >= 100 ? (
          <div className='flex w-full relative group'>
            <img className='w-full aspect-square object-cover hover:scale-105 transition-all grayscale-[100%] brightness-75 hover:shadow-lg hover:grayscale-0 hover:brightness-100' src={`/${project.image}`} width={380} height={380} alt="image" />
            <span className='
              font-inter
              absolute
              top-[20px]
              right-0
              text-white
              inline-block
              w-auto
              h-0
              border-b-[33px]
              border-b-[#323232]
              border-l-[5px]
              border-l-transparent
              border-r-0
              px-[10px]
              font-medium
              text-lg
              tracking-wide
              leading-[1.8]
              transition-all
              group-hover:translate-x-[8px]
              group-hover:drop-shadow-sm
            '>{`${project.sellpercent}% VENDIDO`}</span>
          </div>
        ) : isNaN(project.sellpercent) ? (
          <div className='flex w-full relative group'>
            <img className='w-full aspect-square object-cover hover:scale-105 transition-all hover:shadow-lg hover:brightness-105' src={`/${project.image}`} width={380} height={380} alt="image" />
            <span className='
            font-inter
            absolute
            top-[20px]
            right-0
            text-white
            inline-block
            w-auto
            h-0
            border-b-[33px]
            border-b-[#5b6551]
            border-l-[5px]
            border-l-transparent
            border-r-0
            px-[10px]
            font-medium
            text-lg
            tracking-wide
            leading-[1.8]
            transition-all
            group-hover:translate-x-[8px]
            group-hover:drop-shadow-sm
          '>{`${project.sellpercent}`}</span>
          </div>
        )
          : (
            <div className='flex w-full relative group'>
              <img className='w-full aspect-square object-cover hover:scale-105 transition-all hover:brightness-105 hover:shadow-lg' src={`/${project.image}`} width={380} height={380} alt="image" />
              <span className='
              font-inter
              absolute
              top-[20px]
              right-0
              text-black
              inline-block
              w-auto
              h-0
              border-b-[33px]
              border-b-white
              border-l-[5px]
              border-l-transparent
              border-r-0
              px-[10px]
              font-medium
              text-lg
              tracking-wide
              leading-[1.8]
              transition-all
              group-hover:translate-x-[8px]
              group-hover:drop-shadow-sm
            '>{`${project.sellpercent}% VENDIDO`}</span>
            </div>
          )
        }
        <h2 className='font-medium mb-5 ml-1 sm:mb-2 capitalize xl:text-xl 2xl:text-2xl mt-5'>{project.title}</h2>
        <ul className='pl-5 pr-2 list-disc text-sm'>
          {project.sellpercent !== 100 && project.tags.map((item, index) => (
            <li className='mb-2' key={index}>{item}</li>
          ))}
        </ul>
      </a>

    </div>
  );
}